import { defineStore } from 'pinia';
import permissions from '@/data/permissions';

const PERSIST_KEY = 'MSSP_SESSION';
const PERSIST_DELIMITER = ';';

const emailToName = (email) => {
  if (!email) {
    return '';
  }
  const [localPart, domain] = email.split('@');
  const localParts = localPart.split('.');
  const localPartsCapitalized = localParts.map(
    (part) => part.charAt(0).toUpperCase() + part.slice(1),
  );
  const name = localPartsCapitalized.join(' ');
  const domainName = domain.split('.')[0];
  const domainNameCapitalized = domainName.charAt(0).toUpperCase() + domainName.slice(1);
  if (localParts.length === 1) {
    return `${name} ${domainNameCapitalized}`;
  }

  return name;
};

const useMeStore = defineStore('me', {
  state: () => ({
    id: null,
    email: null,
    token: null,
    dashboardData: {},
  }),
  getters: {
    formatted(state) {
      return {
        name: state.name,
        email: state.email,
        initials: state.initials,
      };
    },
    name(state) {
      return emailToName(state.email);
    },
    initials(state) {
      if (!state.name) {
        return 'N/A';
      }
      const [firstName, lastName] = state.name.split(' ');
      return firstName.charAt(0) + lastName.charAt(0);
    },
    isLoggedIn(state) {
      return !!state.id;
    },
    permissions() {
      return Object.values(permissions);
    },
    hasPermission: (me) => (query) => me.permissions.includes(query),
  },
  actions: {
    login(payload) {
      this.id = payload.id;
      this.email = payload.email;
      this.token = payload.token;
      this.saveSession();
    },
    saveSession() {
      const data = [this.id, this.email, this.token].join(PERSIST_DELIMITER);
      window.localStorage.setItem(PERSIST_KEY, data);
    },
    retrieveSession() {
      const data = window.localStorage.getItem(PERSIST_KEY);
      if (!data) {
        return false;
      }
      const [id, email, token] = data.split(PERSIST_DELIMITER);
      this.id = Number(id);
      this.email = email;
      this.token = token;
      return true;
    },
    clearSession() {
      window.localStorage.removeItem(PERSIST_KEY);
    },
    logout() {
      this.id = null;
      this.email = null;
      this.token = null;
      this.clearSession();
    },
  },
});

export default useMeStore;
