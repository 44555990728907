<!-- eslint-disable vuejs-accessibility/interactive-supports-focus, vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="m-container m-pt-40">
    <PageHeader
      icon="exclamationCircle"
      title="Vyberte aktivitu"
      lead="Máte na výber z troch častí, ktoré tvoria obsah Návštevy na zlepšenie. Je potrebné zrealizovať všetky z nich."
    />
    <ActivityCards
      :activityTypes="lists.activityTypes"
      :activities="visit.activities"
      :employeeGetter="lists.getEmployee"
      editorMode

      @addActivity="createNewActivity($event)"
      @removeActivity="visit.activities.splice($event, 1)"
      @openActivity="router.push({
        name: 'NewVisit/Activity',
        params: {
          activityIndex: $event + 1,
          step: 1,
        },
      })"
    />
  </div>
  <NewVisitBottombar
    hasBack
    :hasContinue="visit.activities.length"
    @continue="$router.push({ name: 'NewVisit/ThankYou' })"
  />
</template>

<script setup>
import { useRouter } from 'vue-router';
import useListsStore from '@/stores/lists';
import useVisitStore from '@/stores/visit';

import PageHeader from '@/components/PageHeader.vue';
import NewVisitBottombar from '@/components/NewVisitBottombar.vue';
import ActivityCards from '@/components/ActivityCards.vue';

const router = useRouter();
const lists = useListsStore();
const visit = useVisitStore();

const createNewActivity = (type) => {
  visit.createNewActivity(type);
  router.push({
    name: 'NewVisit/Activity',
    params: {
      activityIndex: visit.activities.length,
      step: 1,
    },
  });
};

</script>
