<template>
  <div class="base-spinner">
    <div class="base-spinner-object" />
  </div>
</template>

<style scoped>
.base-spinner-object {
  --size: 64px;

  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border-color: var(--white);
  border-style: solid;
  border-bottom-color: var(--yellow);
  border-top-color: var(--blue);
  border-width: 8px;
  animation: spinner 1s cubic-bezier(.38,.13,.63,.9) infinite;
}

@keyframes spinner {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
</style>
