<template>
  <div>
    <header class="branches-header">
      <div class="m-container">
        <InputText
          placeholder="Hľadať pobočku"
          v-model="query"
        />
        <BaseButton
          v-if="allowClosest"
          variant="link"
          size="small"
          class="show-closest"
          @click="$emit('requestClosest')"
        >
          Zobraziť najbližšie
        </BaseButton>
      </div>
    </header>
    <div class="m-container">
      <RecycleScroller
        class="scroller"
        :items="branchesFiltered"
        :itemSize="73"
        keyField="id"
        listTag="ul"
        itemTag="li"
        v-slot="{ item }"
      >
        <ListItemBranch
          :branch="item"
          @click="emit('select', item)"
        />
      </RecycleScroller>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import normalizeString from '@/util/normalizeString';

import { RecycleScroller } from 'vue-virtual-scroller';
import InputText from '@/components/InputText.vue';
import BaseButton from '@/components/BaseButton.vue';
import ListItemBranch from '@/components/ListItemBranch.vue';

const props = defineProps({
  branches: {
    type: Array,
    required: true,
  },
  allowSelectAll: {
    type: Boolean,
  },
  allowClosest: {
    type: Boolean,
  },
});

const emit = defineEmits(['select']);

const query = ref('');

const branchesEnriched = computed(() => {
  if (!props.allowSelectAll) {
    return props.branches;
  }
  return [
    {
      id: 'ALL',
      name: 'Zvoliť všetky',
    },
    ...props.branches,
  ];
});

const branchesFiltered = computed(() => {
  if (!query.value) {
    return branchesEnriched.value;
  }
  const queryNormalized = normalizeString(query.value);
  // eslint-disable-next-line arrow-body-style
  const filtered = branchesEnriched.value.filter((branch) => {
    return normalizeString(branch.name).includes(queryNormalized)
      || normalizeString(branch.address).includes(queryNormalized);
  });
  return filtered;
});

</script>

<style scoped>
.branches-header {
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: var(--white);
  border-bottom: var(--simple-border);
  box-shadow: var(--box-shadow-200);
  position: sticky;
  top: 0;
  z-index: 1;
}

.show-closest {
  margin: 0 auto -12px;
}

.scroller {
  height: calc(100vh - var(--app-header-height) - var(--app-bottombar-height) - 89px);
  margin: 0;
}

</style>
