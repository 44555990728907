<template>
  <InputOptions
    :modelValue="modelValue"
    :options="options"
    useAccent
    @update:modelValue="$emit('update:modelValue', $event)"
  />
</template>

<script setup>
import InputOptions from '@/components/InputOptions.vue';

defineProps({
  modelValue: {
    type: String,
    default: null,
  },
});

const options = [
  {
    value: '1',
    label: '1',
    accent: 'var(--green)',
  },
  {
    value: '2',
    label: '2',
    accent: 'var(--green)',
  },
  {
    value: '3',
    label: '3',
    accent: 'var(--yellow)',
  },
  {
    value: '4',
    label: '4',
    accent: 'var(--red)',
  },
  {
    value: '5',
    label: '5',
    accent: 'var(--red)',
  },
];
</script>
