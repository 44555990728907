<template>
  <div class="m-container">
    <ListItem
      v-for="user in lists.users"
      :key="user"
    >
      <label class="m-stack-horizontal">
        <input
          type="checkbox"
          @change="$event.target.checked
            ? addToSelected(user.id)
            : removeFromSelected(user.id)"
        >
        <h4>{{ user.name || 'N/A' }} ({{user.email}})</h4>
      </label>
    </ListItem>
    <div class="m-pt-24">
      <BaseButton
        @click="submit()"
        :disabled="selected.length === 0"
      >
        Uložiť
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useStatsStore from '@/stores/stats';
import useListsStore from '@/stores/lists';

import ListItem from '@/components/ListItem.vue';
import BaseButton from '@/components/BaseButton.vue';

const router = useRouter();
const stats = useStatsStore();
const lists = useListsStore();

const selected = ref([]);

const addToSelected = (id) => {
  selected.value.push(id);
};

const removeFromSelected = (id) => {
  const index = selected.value.indexOf(id);
  selected.value.splice(index, 1);
};

const submit = () => {
  stats.filter.author = 'SPECIFIC';
  stats.filter.authorsSpecific = selected.value;
  router.push({ name: 'Stats/List/Branches' });
};

</script>
