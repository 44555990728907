<template>
  <div>
    <RouterLink
      v-for="branch in visitsByBranch"
      :key="branch.id"
      :to="{
        name: 'Stats/BranchVisits',
        params: { id: branch.id },
      }"
      class="m-reset-link"
    >
      <ListItemBranchWithVisits
        :branchGetter="branchGetter"
        :branch="branch"
      />
    </RouterLink>
    <div v-if="branchesWithoutVisitsCount">
      <div v-if="!showAll" class="m-mt-16 m-font-size-100 m-color-gray-600">
        Ďalších {{ branchesWithoutVisitsCount }} pobočiek bez návštevy
        <BaseButton variant="link" size="small" leftAligned @click="showAll = true">
          Zobraziť pobočky bez návštevy
        </BaseButton>
      </div>
      <div class="m-pt-24" v-else>
        <h3 class="m-mb-16">Pobočky bez návštevy</h3>
        <ul v-if="showAll">
          <li
            v-for="branch in branchesWithoutVisits"
            :key="branch.id"
          >
            {{ branch.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import ListItemBranchWithVisits from '@/components/ListItemBranchWithVisits.vue';
import BaseButton from '@/components/BaseButton.vue';

const props = defineProps({
  visitsByBranch: {
    type: Array,
    required: true,
  },
  allBranches: {
    type: Array,
    default: () => [],
  },
  branchGetter: {
    type: Function,
    required: true,
  },
});

const showAll = ref(false);

const branchesWithoutVisitsCount = computed(
  () => props.allBranches.length - props.visitsByBranch.length,
);

const visitedIds = computed(() => props.visitsByBranch.map((branch) => branch.id));

const branchesWithoutVisits = computed(() => props.allBranches
  .filter((branch) => !visitedIds.value.includes(branch.id)));

</script>
