<template>
  <div class="m-container m-center-screen -with-bottombar">
    <BaseSpinner
      v-if="isLoading"
    />
    <div
      class="thank-you-stack"
      v-else
    >
      <PageHeader
        icon="checkCircle"
        iconSize="large"
        title="Návšteva bola odoslaná do evidencie"
        lead="Detail návštevy si môžete kedykoľvek pozrieť v zozname návštev."
        center
      />

      <BaseButton
        class="thank-you-button"
        @click="$router.push({ name: 'Dashboard' })"
      >
        Prejsť na hlavnú obrazovku
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useVisitStore from '@/stores/visit';
import { saveVisit } from '@/api';

import PageHeader from '@/components/PageHeader.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseSpinner from '@/components/BaseSpinner.vue';

const visit = useVisitStore();
const isLoading = ref(false);

onMounted(async () => {
  isLoading.value = true;
  await saveVisit(visit.visitTransformed);
  visit.reset();
  isLoading.value = false;
});
</script>

<style scoped>
.thank-you-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thank-you-button {
  width: fit-content;
}
</style>
