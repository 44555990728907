<template>
  <div class="m-container m-pt-40">
    <PageHeader
      icon="exclamationCircle"
      title="Dátum Návštevy na zlepšenie"
      lead="Vyberte dátum vašej Návštevy na zlepšenie."
    />
    <FormSheet>
      <FormGroup>
        <InputText
          type="date"
          :max="max"
          v-model="visit.performedAt"
        />
      </FormGroup>
    </FormSheet>
    <NewVisitBottombar
      hasBack
      hasContinue
      @continue="$router.push({ name: 'NewVisit/Branches' })"
    />
  </div>
</template>

<script setup>
import useVisitStore from '@/stores/visit';
import formatDateISO from '@/util/formatDateISO';

import PageHeader from '@/components/PageHeader.vue';
import FormSheet from '@/components/FormSheet.vue';
import FormGroup from '@/components/FormGroup.vue';
import InputText from '@/components/InputText.vue';
import NewVisitBottombar from '@/components/NewVisitBottombar.vue';

const visit = useVisitStore();

const max = formatDateISO(new Date());
</script>
