<template>
  <div>
    <ElevatedHeader>
      <div class="filter-container">
        <FilterItem
          icon="user"
          label="Autor"
          :value="stats.readable.filter.author"
          @click="$router.push({ name: 'Stats/Filter/Author' })"
        />
        <FilterItem
          icon="store"
          label="Pobočka"
          :value="stats.readable.filter.branch"
          @click="$router.push({ name: 'Stats/Filter/Branches' })"
        />
        <FilterItem
          icon="calendar"
          label="Rozsah"
          :value="stats.readable.filter.datespan"
          @click="$router.push({ name: 'Stats/Filter/Datespan' })"
        />
      </div>
    </ElevatedHeader>
    <div class="m-container">
      <div class="list-header">
        <p class="list-header-overline">Za nastavené obdobie bolo vykonaných</p>
        <h2 class="list-header-count">
          {{ visits.length }} návštev
        </h2>
        <p class="list-header-branches">
          ({{ visitsByBranch.length }} pobočky)
        </p>
        <div class="list-header-actions">
          <BaseButton
            size="small"
            variant="ghost"
            icon="download"
            @click="downloadExport"
          >
            Export štatistík
          </BaseButton>
        </div>
      </div>
    </div>

    <BaseTabs
      v-model="activeTab"
      :options="tabOptions"
    />

    <div class="m-container m-pb-24">
      <RouterView
        :visits="visits"
        :visitsByBranch="visitsByBranch"
        :allBranches="lists.branches"
        :branchGetter="lists.getBranch"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref, computed, onMounted, watch,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import useStatsStore from '@/stores/stats';
import useListsStore from '@/stores/lists';
import { getVisits } from '@/api';
import apiBase from '@/api/apiBase';

import ElevatedHeader from '@/components/ElevatedHeader.vue';
import FilterItem from '@/components/FilterItem.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseTabs from '@/components/BaseTabs.vue';

const stats = useStatsStore();
const lists = useListsStore();
const router = useRouter();
const route = useRoute();

const visits = computed(() => stats.visitsOrdered);

const visitsByBranch = computed(() => {
  const b = [];
  visits.value.forEach((visit) => {
    const match = b.find((branch) => branch.id === visit.branch);
    if (match) {
      match.visits.push(visit);
    } else {
      b.push({
        id: visit.branch,
        visits: [
          visit,
        ],
      });
    }
  });
  return b;
});

const tabOptions = [
  { value: 'Stats/List/Branches', label: 'Pobočky' },
  { value: 'Stats/List/Visits', label: 'Návštevy' },
];
const activeTab = ref(route.name);

const downloadExport = () => {
  const params = new URLSearchParams({ ...stats.filterBackend });
  const paramsString = params.toString();
  const url = `${apiBase}statistics/export?${paramsString}`;
  window.open(url);
};

const loadVisits = async () => {
  const params = { ...stats.filterBackend };
  stats.visits = await getVisits(params);
};

onMounted(() => {
  loadVisits();
});

watch(activeTab, (newValue) => {
  router.push({
    name: newValue,
  });
});

</script>

<style scoped>
.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.list-header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 4px;
  padding: 16px 0;
}

.list-header-overline {
  color: var(--gray-600);
}

.list-header-count {
  color: var(--blue);
}

.list-header-overline {
  width: 100%;
}

.list-header-actions {
  width: 100%;
  margin-top: 8px;
}
</style>
