<template>
  <a
    :href="DOCUMENTS_BASE + document.file"
    target="_blank"
    rel="noopener noreferrer"
    class="m-reset-link"
  >
    <ListItem>
      <template #media>
        <img
          class="list-item-document-thumbnail"
          :src="DOCUMENTS_BASE + document.thumbnail"
          :alt="document.name"
        >
      </template>
      <h4 class="m-font-bold m-font-size-300">{{ document.name }}</h4>
      <p class="m-color-gray-600">{{ document.description }}</p>
    </ListItem>
  </a>
</template>

<script setup>
import apiBase from '@/api/apiBase';

import ListItem from '@/components/ListItem.vue';

defineProps({
  document: {
    type: Object,
    default: () => ({}),
  },
});

const DOCUMENTS_BASE = `${apiBase}documents/`;
</script>

<style scoped>
.list-item-document-thumbnail {
  width: 80px;
  min-width: 80px;
  border: var(--simple-border);
  border-radius: var(--border-radius-sm);
}

</style>
