<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="document-editor">
    <img
      :src="DOCUMENTS_BASE + document.thumbnail"
      alt=""
      class="document-editor-thumbnail"
    >
    <FormGroup
      label="Názov dokumentu"
    >
      <InputText
        v-model="document.name"
      />
    </FormGroup>
    <FormGroup
      label="Popis"
    >
      <InputText
        v-model="document.description"
      />
    </FormGroup>
    <div class="document-editor-actions">
      <BaseButton
        variant="link-danger"
        class="document-editor-delete"
        leftAligned
        @click="$emit('delete')"
      >
        Odstrániť dokument
      </BaseButton>
      <CircleButton
        :disabled="isFirst"
        icon="arrowUp"
        @click="$emit('moveUp')"
      />
      <CircleButton
        :disabled="isLast"
        icon="arrowDown"
        @click="$emit('moveDown')"
      />
    </div>
  </div>
</template>

<script setup>
import apiBase from '@/api/apiBase';

import FormGroup from '@/components/FormGroup.vue';
import InputText from '@/components/InputText.vue';
import BaseButton from '@/components/BaseButton.vue';
import CircleButton from '@/components/CircleButton.vue';

defineProps({
  document: {
    type: Object,
    default: () => ({
      title: '',
      subtitle: '',
    }),
  },
  isFirst: {
    type: Boolean,
  },
  isLast: {
    type: Boolean,
  },
});

defineEmits('delete', 'moveUp', 'moveDown');

const DOCUMENTS_BASE = `${apiBase}documents/`;

</script>

<style scoped>

.document-editor {
  border: var(--simple-border);
  border-radius: var(--border-radius);
  padding: 16px;
  background-color: #FCFCFC;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.document-editor-thumbnail {
  display: block;
  width: 80px;
  border: var(--simple-border);
  border-radius: var(--border-radius-sm);
}

.document-editor-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.document-editor-delete {
  margin-right: auto;
}

</style>
