<template>
  <a
    :href="DOCUMENTS_BASE + document.file"
    target="_blank"
    rel="noopener noreferrer"
    class="m-reset-link document-tile"
  >
    <img
      class="document-tile-thumbnail"
      :src="DOCUMENTS_BASE + document.thumbnail"
      :alt="document.name"
    >
    <div class="document-tile-body">
      <h4 class="m-font-bold m-font-size-200">{{ document.name }}</h4>
      <p class="m-font-size-100 m-color-gray-600">{{ document.description }}</p>
    </div>
  </a>
</template>

<script setup>
import apiBase from '@/api/apiBase';

defineProps({
  document: {
    type: Object,
    default: () => ({}),
  },
});

const DOCUMENTS_BASE = `${apiBase}documents/`;
</script>

<style scoped>
.document-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: var(--simple-border);
  border-radius: var(--border-radius-sm);
  background-color: var(--blue-5);
  &:hover {
    background-color: var(--elevated);
  }
}

.document-tile-thumbnail {
  height: 120px;
}

.document-tile-body {
  width: 100%;
  padding: 12px 16px;
  flex: 1;
}

</style>
