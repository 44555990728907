import { defineStore } from 'pinia';
import { formatISO } from 'date-fns';
import useListsStore from './lists';

const defaultVisitState = () => ({
  id: null,
  performedAt: formatISO(new Date(), { representation: 'date' }),
  branch: null,
  activities: [],
});

const defaultActivityState = () => ({
  type: null,
  employee: {},
  screens: [],
  agreement: {
    description: '',
    attachments: [],
  },
});

const useVisitStore = defineStore('visit', {
  state: defaultVisitState,
  getters: {
    visitTransformed(visit) {
      return {
        performedAt: visit.performedAt,
        branch: visit.branch,
        activities: visit.activities.map((activityRaw) => ({
          type: activityRaw.type,
          employee: activityRaw.employee,
          agreement: {
            description: activityRaw.agreement.description,
            attachments: activityRaw.agreement.attachments,
          },
          screens: activityRaw.screens.map((screen) => ({
            label: screen.label,
            responseType: screen.responseType,
            questions: screen.answers,
          })),
        })),
      };
    },
  },
  actions: {
    createNewActivity(type) {
      const lists = useListsStore();
      const activityType = lists.getActivityType(type);

      this.activities.push({
        ...defaultActivityState(),
        type,
        screens: activityType.screens.map((screen) => ({
          ...screen,
          answers: screen.questions.map((question) => ({
            question,
            answer: null,
          })),
        })),
      });
    },
    setVisit(visitState) {
      this.id = visitState.id;
      this.performedAt = visitState.performedAt;
      this.branch = visitState.branch;
      this.activities = visitState.activities;
    },
    reset() {
      const defaultState = defaultVisitState();
      this.setVisit(defaultState);
    },
  },
});

export default useVisitStore;
