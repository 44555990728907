<template>
  <InputOptions
    :modelValue="modelValue"
    :options="options"
    @update:modelValue="$emit('update:modelValue', $event)"
  />
</template>

<script setup>
import InputOptions from '@/components/InputOptions.vue';

defineProps({
  modelValue: {
    type: String,
    default: null,
  },
});

const options = [
  {
    value: 'GOOD',
    label: '👍',
    icon: 'thumbsUp',
    accent: 'var(--green)',
  },
  {
    value: 'BAD',
    label: '👎',
    icon: 'thumbsDown',
    accent: 'var(--red)',
  },
  {
    value: 'SKIP',
    label: '💭',
    icon: 'chat',
    accent: 'var(--gray-800)',
  },
];
</script>
