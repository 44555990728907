<template>
  <div class="app-header">
    <RouterLink
      v-if="type === headerTypes.REGULAR || type === headerTypes.LOGO_ONLY"
      class="m-reset-link"
      :to="{ name: 'Dashboard' }"
    >
      <div class="app-header-brand">
        <img
          class="app-header-icon"
          src="@/assets/slp-logo.svg"
          alt=""
        >
        <div class="app-header-title">
          eManažer
        </div>
      </div>
    </RouterLink>
    <button
      v-if="type === headerTypes.BACK"
      type="button"
      class="app-header-back m-reset-button"
      @click="$router.back()"
    >
      <BaseIcon
        icon="arrowLeft"
      />
      Naspäť
    </button>
    <RouterLink
      v-if="type === headerTypes.BACK || type === headerTypes.REGULAR"
      class="m-reset-link"
      :to="{ name: 'Account' }"
    >
      <AccountAvatar
        :size="32"
        :initials="me.initials"
      />
    </RouterLink>
  </div>
</template>

<script setup>
import useMeStore from '@/stores/me';
import headerTypes from '@/data/headerTypes';

import AccountAvatar from '@/components/AccountAvatar.vue';
import BaseIcon from '@/components/BaseIcon.vue';

defineProps({
  type: {
    type: String,
    default: 'REGULAR',
  },
});

const me = useMeStore();
</script>

<style scoped>
.app-header {
  background-color: var(--yellow);
  color: var(--blue);
  padding: 16px;
  min-height: var(--app-header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-header-brand {
  display: flex;
  align-items: center;
  gap: 8px;
}

.app-header-icon {
  width: 36px;
  height: 36px;
}

.app-header-title {
  font-size: 20px;
  font-weight: 700;
}

.app-header-back {
  display: flex;
  align-items: center;
  gap: 8px;
}

</style>
