<template>
  <div>
    <ElevatedHeader>
      <h2>Správa dokumentov</h2>
    </ElevatedHeader>
    <div class="m-container documents-manage-stack">
      <DocumentEditCard
        v-for="(document, i) in list"
        :key="i"
        :document="document"
        :isFirst="i === 0"
        :isLast="i === list.length - 1"
        @moveUp="docHandler.moveUp(i)"
        @moveDown="docHandler.moveDown(i)"
        @delete="docHandler.delete(i)"
      />
      <DocumentUploader
        label="Nahrať nový dokument"
        icon="clipboard"
        @upload="docHandler.new($event)"
      />
      <BaseButton
        @click="submit()"
      >
        Uložiť
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { toRef } from 'vue';
import { useRouter } from 'vue-router';
import useMetaStore from '@/stores/meta';
import useListsStore from '@/stores/lists';
import { editDocuments } from '@/api';

import ElevatedHeader from '@/components/ElevatedHeader.vue';
import DocumentEditCard from '@/components/DocumentEditCard.vue';
import DocumentUploader from '@/components/DocumentUploader.vue';
import BaseButton from '@/components/BaseButton.vue';

const meta = useMetaStore();
const lists = useListsStore();
const list = toRef(lists.documents.list);
const router = useRouter();

const createNewDocument = ({ order, file, thumbnail }) => ({
  id: null,
  name: '',
  description: '',
  order,
  file,
  thumbnail,
});

const move = (array, from, to) => {
  array.splice(to, 0, array.splice(from, 1)[0]);
};

const docHandler = {
  delete(i) {
    list.value.splice(i, 1);
  },
  moveUp(i) {
    if (i === 0) {
      return;
    }
    move(list.value, i, i - 1);
  },
  moveDown(i) {
    if (i === list.value.length - 1) {
      return;
    }
    move(list.value, i, i + 1);
  },
  new(fileUpload) {
    list.value.push(createNewDocument({
      order: list.value.length,
      file: fileUpload.file,
      thumbnail: fileUpload.thumbnail,
    }));
  },
};

const submit = async () => {
  meta.loading = true;
  const newList = list.value.map((document, i) => ({
    ...document,
    order: i + 1,
  }));
  const docs = await editDocuments(newList);
  lists.documents.list = docs;
  meta.loading = false;
  router.push({ name: 'DocumentsList' });
};

</script>

<style scoped>
.documents-manage-stack {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
