import { defineStore } from 'pinia';

const useListsStore = defineStore('lists', {
  state: () => ({
    branches: [],
    activityTypes: [],
    employees: [],
    documents: {
      isLoading: false,
      list: [],
    },
    users: [],
  }),
  getters: {
    getActivityType: (vm) => (activityTypeId) => {
      const match = vm.activityTypes.find((at) => at.id === activityTypeId);
      return match;
    },
    getBranch: (vm) => (branchId) => {
      const match = vm.branches.find((branch) => branch.id === branchId);
      return match;
    },
    getEmployee: (vm) => (employee) => {
      if (!employee.id) {
        return employee;
      }
      const match = vm.employees.find((record) => record.id === employee.id);
      return match;
    },
  },
});

export default useListsStore;
