import { defineStore } from 'pinia';
import { startOfMonth, endOfMonth } from 'date-fns';
import formatDate from '@/util/formatDate';
import formatDateISO from '@/util/formatDateISO';
import useMeStore from './me';

const AUTHOR_TRANSLATIONS = {
  ME: 'Ja',
  ALL_SUBORDINATES: 'Všetci moji podriadení',
  SPECIFIC: 'Vybrať konkrétne',
};

const BRANCH_TRANSLATIONS = {
  ALL: 'Všetky',
  SPECIFIC: 'Vybrať konkrétne',
};

const DATESPAN_TRANSLATIONS = {
  THIS_MONTH: 'Tento mesiac',
  SPECIFIC: 'Vybrať konkrétne',
};

// TODO: Should be probably simplified
const useStatsStore = defineStore('stats', {
  state: () => ({
    filter: {
      author: 'ME',
      authorsSpecific: [],
      branch: 'ALL',
      branchesSpecific: [],
      datespan: 'THIS_MONTH',
      datespanSpecific: {
        from: null,
        to: null,
      },
    },
    visits: [],
  }),
  getters: {
    readable(state) {
      const FORMAT = 'd. M.';
      const { filter } = state;

      const author = filter.author === 'SPECIFIC'
        ? `${filter.authorsSpecific.length}x`
        : AUTHOR_TRANSLATIONS[filter.author];

      const branch = filter.branch === 'SPECIFIC'
        ? filter.branchesSpecific.join(', ')
        : BRANCH_TRANSLATIONS[filter.branch];

      const datespan = filter.datespan === 'SPECIFIC'
        ? `${formatDate(filter.datespanSpecific.from, FORMAT)} až ${formatDate(filter.datespanSpecific.to, FORMAT)}`
        : DATESPAN_TRANSLATIONS[filter.datespan];
      return {
        filter: {
          author,
          branch,
          datespan,
        },
      };
    },
    filterApplicable(state) {
      const me = useMeStore();
      const today = new Date();

      let author;
      if (state.filter.author === 'ALL_SUBORDINATES') {
        author = null;
      }
      if (state.filter.author === 'ME') {
        author = me.id;
      }
      if (state.filter.author === 'SPECIFIC') {
        author = state.filter.authorsSpecific;
      }
      return {
        author,
        branch: state.filter.branch === 'ALL'
          ? null
          : state.filter.branchesSpecific,
        datespan: state.filter.datespan === 'THIS_MONTH'
          ? { from: startOfMonth(today), to: endOfMonth(today) }
          : state.filter.datespanSpecific,
      };
    },
    filterBackend(state) {
      const output = {
        periodStart: formatDateISO(state.filterApplicable.datespan.from),
        periodEnd: formatDateISO(state.filterApplicable.datespan.to),
      };
      if (state.filterApplicable.author) {
        output.authors = state.filterApplicable.author;
      }
      if (state.filterApplicable.branch) {
        output.branches = state.filterApplicable.branch.join(',');
      }
      return output;
    },

    visitsOrdered(state) {
      const visits = [...state.visits];
      return visits.sort((a, b) => b.performedAt.localeCompare(a.performedAt));
    },
  },
});

export default useStatsStore;
