<template>
  <div>
    <ElevatedHeader>
      <h2 class="m-mb-16">Knižnica dokumentov</h2>
      <BaseButton
        v-if="me.hasPermission(permissions.DOCUMENTS_MANAGE)"
        variant="ghost"
        size="small"
        @click="$router.push({ name: 'DocumentsManage' })"
      >
        Spravovať dokumenty
      </BaseButton>
    </ElevatedHeader>
    <div
      v-if="!meta.loading"
      class="m-container"
    >
      <p v-if="!lists.documents.list.length" class="m-pt-24">
        Žiadne dokumenty nie sú k dispozícii.
      </p>
      <ListItemDocument
        v-else
        v-for="(document, i) in lists.documents.list"
        :key="i"
        :document="document"
      />
    </div>
  </div>
</template>

<script setup>
import useMeStore from '@/stores/me';
import useMetaStore from '@/stores/meta';
import useListsStore from '@/stores/lists';
import permissions from '@/data/permissions';

import ElevatedHeader from '@/components/ElevatedHeader.vue';
import ListItemDocument from '@/components/ListItemDocument.vue';
import BaseButton from '@/components/BaseButton.vue';

const me = useMeStore();
const meta = useMetaStore();
const lists = useListsStore();

</script>
