const BASES = [
  {
    name: 'test',
    url: 'https://backend-test.postamanazer.sk/',
    usedOn: ['test.postamanazer.sk'],
  },
  {
    name: 'prod',
    url: 'https://backend.postamanazer.sk/',
    usedOn: ['postamanazer.sk'],
  },
];

const getBaseByName = (name) => {
  const matchedBase = BASES.find((record) => record.name === name);
  return matchedBase;
};

const getBaseFromDotEnv = () => getBaseByName(import.meta.env.VITE_API_BASE);

const getBaseFromDomain = () => {
  const matchedBase = BASES.find(
    (record) => record.usedOn.includes(window.location.hostname),
  );
  if (matchedBase) {
    return matchedBase;
  }
  return null;
};

const getApiBase = () => {
  if (import.meta.env.VITE_API_BASE) {
    return getBaseFromDotEnv()
      ? getBaseFromDotEnv().url
      : import.meta.env.VITE_API_BASE;
  }
  if (getBaseFromDomain()) {
    return getBaseFromDomain().url;
  }
  return getBaseByName('test').url;
};

const apiBase = getApiBase();

export default apiBase;
