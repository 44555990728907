<template>
  <div class="form-error">
    <slot />
  </div>
</template>

<script setup>
</script>

<style scoped>
.form-error {
  padding: 16px;
  border-radius: var(--border-radius);
  background-color: var(--gray-100);
  border: 2px solid var(--gray-600);
}
</style>
