<template>
  <div class="m-container m-pt-24">
    <h2 class="m-color-blue">Zvoľte autora</h2>
    <ListItem
      @click="selectAuthor('ME')"
    >
      <template #media>
        <BaseIcon icon="user" :size="24" />
      </template>
      <h4>Ja</h4>
    </ListItem>
    <ListItem
      @click="selectAuthor('ALL_SUBORDINATES')"
    >
      <template #media>
        <BaseIcon icon="userGroup" :size="24" />
      </template>
      <h4>Všetci moji podriadení</h4>
    </ListItem>
    <ListItem
      @click="router.push({ name: 'Stats/Filter/Author/Specific' })"
    >
      <template #media>
        <BaseIcon icon="userCircle" :size="24" />
      </template>
      <h4>Vybrať konkrétne</h4>
    </ListItem>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import useStatsStore from '@/stores/stats';

import ListItem from '@/components/ListItem.vue';
import BaseIcon from '@/components/BaseIcon.vue';

const router = useRouter();
const stats = useStatsStore();

const selectAuthor = (author) => {
  stats.filter.author = author;
  stats.filter.authorsSpecific = [];
  router.push({ name: 'Stats/List/Branches' });
};

</script>
