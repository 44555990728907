<template>
  <main>
    <div class="m-container">
      <div class="m-stack-vertical">
        <h2>Inputs</h2>
        <FormGroup
          label="InputText"
        >
          <InputText />
        </FormGroup>
        <FormGroup
          label="InputTextarea"
        >
          <InputTextarea />
        </FormGroup>
        <FormGroup
          label="InputOptionsTrio"
        >
          <InputOptionsTrio v-model="optionsValue" />
        </FormGroup>
        <FormGroup
          label="InputOptionsSpectrum"
        >
          <InputOptionsSpectrum v-model="optionsValue" />
        </FormGroup>

        <h2>Buttons / Regular</h2>
        <BaseButton variant="primary">
          Primary
        </BaseButton>
        <BaseButton variant="secondary">
          Secondary
        </BaseButton>
        <BaseButton variant="tertiary">
          Tertiary
        </BaseButton>
        <BaseButton variant="ghost">
          Ghost
        </BaseButton>
        <BaseButton variant="link">
          Button
        </BaseButton>
        <BaseButton disabled>
          Button
        </BaseButton>

        <h2>Buttons / Small</h2>
        <BaseButton variant="ghost" size="small">
          Ghost
        </BaseButton>

      </div>
      <h2>Others</h2>
      <NavTile
        label="Štatistiky podriadených"
      />
      <PageHeader
        title="Vyberte dátum návštevy"
        lead="Amet sagittis in mauris aenean neque. Neque consectetur mauris id morbi auctor."
      />

      <div />
      <ListItemBranch
        :branch="{
          name: 'Bratislava 24',
          address: 'Mlynské nivy 19034/5A, 82004',
        }"
      />
      <ListItemEmployee
        :employee="{
          name: 'Jana Bystrická',
          number: '1223',
          jobTitle: 'Vedúci pošty',
        }"
      />
      <ListItemDocument
        :document="null"
      />
    </div>
  </main>
</template>

<script setup>
import { ref } from 'vue';

import FormGroup from '@/components/FormGroup.vue';
import InputText from '@/components/InputText.vue';
import InputTextarea from '@/components/InputTextarea.vue';
import BaseButton from '@/components/BaseButton.vue';
import NavTile from '@/components/NavTile.vue';
import PageHeader from '@/components/PageHeader.vue';
import ListItemDocument from '@/components/ListItemDocument.vue';
import ListItemBranch from '@/components/ListItemBranch.vue';
import ListItemEmployee from '@/components/ListItemEmployee.vue';
import InputOptionsTrio from '@/components/InputOptionsTrio.vue';
import InputOptionsSpectrum from '@/components/InputOptionsSpectrum.vue';

const optionsValue = ref(null);

</script>
