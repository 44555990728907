<template>
  <FormSheet
    @submit="save"
    class="m-container m-pt-24"
  >
    <h2>Zvoľte rozsah dátumov</h2>
    <FormGroup
      label="Dátum od"
    >
      <InputText
        type="date"
        v-model="dates.from"
      />
    </FormGroup>
    <FormGroup
      label="Dátum do"
    >
      <InputText
        type="date"
        v-model="dates.to"
      />
    </FormGroup>
    <BaseButton
      type="submit"
    >
      Uložiť
    </BaseButton>
  </FormSheet>
</template>

<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { parseISO } from 'date-fns';
import formatDateISO from '@/util/formatDateISO';
import useStatsStore from '@/stores/stats';

import FormSheet from '@/components/FormSheet.vue';
import FormGroup from '@/components/FormGroup.vue';
import InputText from '@/components/InputText.vue';
import BaseButton from '@/components/BaseButton.vue';

const router = useRouter();
const stats = useStatsStore();

const dates = reactive({
  from: formatDateISO(stats.filterApplicable.datespan.from),
  to: formatDateISO(stats.filterApplicable.datespan.to),
});

const save = () => {
  stats.filter.datespan = 'SPECIFIC';
  stats.filter.datespanSpecific.from = parseISO(dates.from);
  stats.filter.datespanSpecific.to = parseISO(dates.to);
  router.push({ name: 'Stats/List/Branches' });
};

</script>
