<template>
  <header
    class="page-header"
    :class="{
      '-border': border,
      '-center': center,
    }"
  >
    <div
      v-if="icon"
      class="page-header-icon"
      :style="{
        '--size': `${currentIconSize.circle}px`,
      }"
    >
      <BaseIcon
        :icon="icon"
        :size="currentIconSize.icon"
      />
    </div>
    <h2
      v-if="title"
      class="page-header-title"
    >
      {{ title }}
    </h2>
    <p
      v-if="lead"
      class="page-header-lead"
    >
      {{ lead }}
    </p>
  </header>
</template>

<script setup>
import { computed } from 'vue';

import BaseIcon from '@/components/BaseIcon.vue';

const ICON_SIZES = {
  regular: { circle: 56, icon: 36 },
  large: { circle: 86, icon: 56 },
};

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  lead: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: null,
  },
  iconSize: {
    type: String,
    default: 'regular',
  },
  border: {
    type: Boolean,
    default: false,
  },
  center: {
    type: Boolean,
    default: false,
  },
});

const currentIconSize = computed(() => ICON_SIZES[props.iconSize]);
</script>

<style scoped>
.page-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
  /* margin-top: 40px */;
  &.-border {
    padding-bottom: 32px;
    border-bottom: 1px solid var(--gray-200);
  }
  &.-center {
    text-align: center;
    align-items: center;
  }
}

.page-header-icon {
  width: var(--size);
  height: var(--size);
  color: var(--blue);
  background-color: var(--elevated);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-header-title {
  color: var(--blue);
}

</style>
