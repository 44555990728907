import { createRouter, createWebHistory } from 'vue-router';
import ComponentsView from '@/views/ComponentsView.vue';

import LoginView from '@/views/LoginView.vue';
import DashboardView from '@/views/DashboardView.vue';

import NewVisitView from '@/views/NewVisit/NewVisitView.vue';
import DateView from '@/views/NewVisit/DateView.vue';
import BranchesView from '@/views/NewVisit/BranchesView.vue';
import ActivitiesListView from '@/views/NewVisit/ActivitiesListView.vue';
import ActivityView from '@/views/NewVisit/Activity/ActivityView.vue';
import ThankYouView from '@/views/NewVisit/ThankYouView.vue';

import StatsView from '@/views/Stats/StatsView.vue';
import StatsListView from '@/views/Stats/StatsListView.vue';
import StatsListVisitsView from '@/views/Stats/StatsListVisitsView.vue';
import StatsListBranchesView from '@/views/Stats/StatsListBranchesView.vue';

import StatsBranchVisitsView from '@/views/Stats/StatsBranchVisitsView.vue';
import StatsFilterBranchView from '@/views/Stats/StatsFilterBranchView.vue';
import StatsFilterDatespanView from '@/views/Stats/StatsFilterDatespanView.vue';
import StatsFilterAuthorView from '@/views/Stats/StatsFilterAuthorView.vue';
import StatsFilterAuthorSpecificView from '@/views/Stats/StatsFilterAuthorSpecificView.vue';
import StatsDetailView from '@/views/Stats/StatsDetailView.vue';
import StatsDetailActivityView from '@/views/Stats/StatsDetailActivityView.vue';

import DocumentsListView from '@/views/Documents/DocumentsListView.vue';
import DocumentManageView from '@/views/Documents/DocumentsManageView.vue';

import AccountView from '@/views/AccountView.vue';
import AuthChangePasswordView from '@/views/Auth/AuthChangePasswordView.vue';
import AuthRequestPasswordResetView from '@/views/Auth/AuthRequestPasswordResetView.vue';
import AuthSubmitPasswordResetView from '@/views/Auth/AuthSubmitPasswordResetView.vue';

import NotFoundView from '@/views/NotFoundView.vue';

import headerTypes from '@/data/headerTypes';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 };
  },
  routes: [
    {
      path: '/components',
      name: 'Components',
      component: ComponentsView,
    },
    {
      path: '/login',
      alias: '/',
      name: 'Login',
      component: LoginView,
      meta: { header: headerTypes.NONE },
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: DashboardView,
      meta: { header: headerTypes.REGULAR },
    },
    {
      path: '/new-visit',
      name: 'NewVisit',
      component: NewVisitView,
      meta: { header: headerTypes.LOGO_ONLY },
      children: [
        {
          path: 'date',
          name: 'NewVisit/Date',
          component: DateView,
        },
        {
          path: 'branches',
          name: 'NewVisit/Branches',
          component: BranchesView,
        },
        {
          path: 'activities-list',
          name: 'NewVisit/ActivitiesList',
          component: ActivitiesListView,
        },
        {
          path: 'activity/:activityIndex/:step',
          name: 'NewVisit/Activity',
          component: ActivityView,
          props: true,
        },
        {
          path: 'thank-you',
          name: 'NewVisit/ThankYou',
          component: ThankYouView,
        },
      ],
    },

    {
      path: '/stats',
      name: 'Stats',
      component: StatsView,
      children: [
        {
          path: 'list',
          name: 'Stats/List',
          component: StatsListView,
          meta: { header: headerTypes.REGULAR },
          children: [
            {
              path: 'visits',
              name: 'Stats/List/Visits',
              component: StatsListVisitsView,
              meta: { header: headerTypes.REGULAR },
            },
            {
              path: 'branches',
              name: 'Stats/List/Branches',
              component: StatsListBranchesView,
              meta: { header: headerTypes.REGULAR },
            },
          ],
        },
        {
          path: 'filter/branches',
          name: 'Stats/Filter/Branches',
          component: StatsFilterBranchView,
          meta: { header: headerTypes.BACK },
        },
        {
          path: 'filter/datespan',
          name: 'Stats/Filter/Datespan',
          component: StatsFilterDatespanView,
          meta: { header: headerTypes.BACK },
        },
        {
          path: 'filter/author',
          name: 'Stats/Filter/Author',
          component: StatsFilterAuthorView,
          meta: { header: headerTypes.BACK },
        },
        {
          path: 'filter/author/specific',
          name: 'Stats/Filter/Author/Specific',
          component: StatsFilterAuthorSpecificView,
          meta: { header: headerTypes.BACK },
        },

        {
          path: 'branch/:id',
          name: 'Stats/BranchVisits',
          component: StatsBranchVisitsView,
          props: true,
          meta: { header: headerTypes.BACK },
        },
        {
          path: 'detail/:id',
          name: 'Stats/Detail',
          component: StatsDetailView,
          props: true,
          meta: { header: headerTypes.BACK },
        },
        {
          path: 'detail/:visitId/activity/:activityId',
          name: 'Stats/Detail/Activity',
          component: StatsDetailActivityView,
          props: true,
          meta: { header: headerTypes.BACK },
        },
      ],
    },

    {
      path: '/documents',
      name: 'DocumentsList',
      component: DocumentsListView,
      meta: { header: headerTypes.REGULAR },
    },
    {
      path: '/documents/manage',
      name: 'DocumentsManage',
      component: DocumentManageView,
      meta: { header: headerTypes.BACK },
    },

    {
      path: '/account',
      name: 'Account',
      component: AccountView,
      meta: { header: headerTypes.REGULAR },
    },
    {
      path: '/auth/request-reset',
      name: 'AuthRequestReset',
      component: AuthRequestPasswordResetView,
      meta: { header: headerTypes.NONE },
    },
    {
      path: '/auth/reset-password/:token',
      name: 'AuthResetPassword',
      component: AuthSubmitPasswordResetView,
      props: true,
      meta: { header: headerTypes.NONE },
    },
    {
      path: '/auth/change-password',
      name: 'AuthChangePassword',
      component: AuthChangePasswordView,
      meta: { header: headerTypes.BACK },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFoundView,
    },
  ],
});

export default router;
