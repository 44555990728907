import axios from 'axios';
import useMeStore from '@/stores/me';
import apiBase from '@/api/apiBase';

const instance = axios.create({
  baseURL: apiBase,
  timeout: 15 * 1000,
});

// Helpers
const getToken = () => {
  const me = useMeStore();
  return me.token;
};

const getConfig = () => ({
  headers: {
    Authorization: getToken() ? `Bearer ${getToken()}` : null,
  },
});

// Me
const login = async (payload) => {
  const me = useMeStore();
  const response = await instance.post('/me/login', payload);
  me.login({
    id: response.data.user.id,
    email: response.data.user.email,
    token: response.data.token,
  });
  return response.data;
};

const changePassword = async (payload) => {
  const response = await instance.post('/me/change-password', payload, getConfig());
  return response.data;
};

const requestPasswordReset = async (email) => {
  const response = await instance.post('/me/password-reset/request', { email });
  return response.data;
};

const submitPasswordReset = async (payload) => {
  const response = await instance.post('/me/password-reset/submit', payload);
  return response.data;
};

const getDashboardData = async () => {
  const response = await instance.get('/me/dashboard-stats', getConfig());
  return response.data;
};

// Visit
const getBranches = async (coordinates) => {
  const response = await instance.get('/visit/branches', {
    ...getConfig(),
    params: coordinates,
  });
  return response.data.branches;
};

const getActivityTypes = async () => {
  const response = await instance.get('/visit/activity-types', getConfig());
  return response.data.activityTypes;
};

const getEmployees = async () => {
  const response = await instance.get('/visit/employees', getConfig());
  return response.data.employees;
};

const uploadAttachment = async (attachment) => {
  const formData = new FormData();
  formData.append('attachment', attachment);
  const response = await instance.post('/visit/attachment', formData, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'multipart/form-data',
    },
    timeout: 60 * 1000,
  });
  if (response.data.fileName) {
    return response.data.fileName;
  }
  return null;
};

const saveVisit = async (visit) => {
  const response = await instance.post('visit/', visit, getConfig());
  return response.data.employees;
};

const deleteVisit = async (id) => {
  const response = await instance.delete(`visit/${id}`, getConfig());
  return response.data.employees;
};

// Statistics
const getVisits = async (params) => {
  const response = await instance.get('/statistics/', {
    params,
    ...getConfig(),
  });
  return response.data.visits;
};

const getVisit = async (id) => {
  const response = await instance.get(`/visit/${id}`, getConfig());
  return response.data.visit;
};

const getUsers = async () => {
  const response = await instance.get('/statistics/authors', getConfig());
  return response.data.authors;
};

// Documents
const getDocuments = async () => {
  const response = await instance.get('/documents/', getConfig());
  const { documents } = response.data;
  documents.sort((a, b) => a.order - b.order);
  return documents;
};

const editDocuments = async (payload) => {
  const response = await instance.put('/documents/', payload, getConfig());
  const { documents } = response.data;
  documents.sort((a, b) => a.order - b.order);
  return documents;
};

const uploadDocument = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await instance.post('/documents/file', formData, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'multipart/form-data',
    },
    timeout: 60 * 1000,
  });
  if (response.data.fileUpload) {
    return response.data.fileUpload;
  }
  return null;
};

export {
  login,
  changePassword,
  requestPasswordReset,
  submitPasswordReset,
  getDashboardData,

  getBranches,
  getActivityTypes,
  getEmployees,
  uploadAttachment,
  saveVisit,
  deleteVisit,

  getVisits,
  getVisit,
  getUsers,

  getDocuments,
  editDocuments,
  uploadDocument,
};
