<template>
  <div
    class="base-icon"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
      minWidth: `${size}px`,
    }"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :d="path"
      />
    </svg>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const icons = {
  arrowRight: 'm9 20 8-8-8-8',
  arrowLeft: 'm15 20-8-8 8-8',
  arrowDown: 'm4 8 8 8 8-8',
  arrowUp: 'm20 16-8-8-8 8',
  box: 'M10 13h4m6-5v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8m17 0V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v3z',
  calendar: 'M20 9H4m3-6v2m10-2v2m-1 10.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M6 21h12a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2',
  cross: 'M8.5 8.5L15.5 15.5M15.5 8.5L8.5 15.5',
  camera: 'M13 5H9.328a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 5.672 7H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7m-3-7h6m-3 3V2m-9 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8',
  chat: 'M12.29 17.998C18.097 17.934 21 15.918 21 11c0-5-3-7-9-7s-9 2-9 7c0 3.077 1.136 5.018 3.409 6.056L5 21z',
  checkCircle: 'm16 9-6 6.5L7.5 13m4.5 8a9 9 0 1 0 0-18 9 9 0 0 0 0 18',
  chevronUp: 'm6 15 5.293-5.293a1 1 0 0 1 1.414 0L18 15',
  clipboard: 'M9 17h3M9 9h6m-6 4h6M10 2h4M7 21h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2',
  download: 'm7 19-1.217-.005a5 5 0 0 1-.115-9.984A7.002 7.002 0 0 1 18.93 11L19 11a4 4 0 0 1 .2 7.995L17 19m-5-9v8m0 0 3-3m-3 3-3-3',
  edit: 'M12 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6M9 15v-2.5l8.75-8.75c.69-.69 1.81-.69 2.5 0v0c.69.69.69 1.81 0 2.5L15.5 11l-4 4z',
  exclamationCircle: 'M12 16h.01M12 8v4m0 9a9 9 0 1 0 0-18 9 9 0 0 0 0 18',
  flow: 'M12 12h9m0 0-4-4m4 4-4 4m-8-4h.01M6 12h.01M3 12h.01',
  folder: 'M3 7a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 12.328 7H19a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z',
  questionCircle: 'M12 17h.01M12 14c.89-1.906 3-1.766 3-4 0-1.5-1-3-3-3-1.548 0-2.497.898-2.847 2M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18',
  home: 'M19 7.906V18a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V7.906M2 10l8.853-6.197a2 2 0 0 1 2.294 0L22 10M12 11v4m2-2h-4',
  lock: 'M12 14v2M8 9V6a4 4 0 1 1 8 0v3M7 21h10a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2',
  package: 'm20 7.5-7.51-4.224a1 1 0 0 0-.98 0L8 5.25M20 7.5v8.415a1 1 0 0 1-.51.872L12 21m8-13.5-4 2.25M12 21l-7.49-4.213a1 1 0 0 1-.51-.872V7.5M12 21v-9M4 7.5l8 4.5M4 7.5l4-2.25M12 12l4-2.25m0 0-8-4.5',
  password: 'M13.667 9 12 12m0 0-1.667-3M12 12H9.5m2.5 0h2.5M12 12l-1.667 3M12 12l1.667 3m-7.5-6L4.5 12m0 0L2.833 9M4.5 12H2m2.5 0H7m-2.5 0-1.667 3M4.5 12l1.667 3m15-6L19.5 12m0 0-1.667-3m1.667 3H17m2.5 0H22m-2.5 0-1.667 3m1.667-3 1.667 3',
  search: 'M13.39 13.39 19 19m-9.5-4a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11',
  shine: 'M10 4a6 6 0 0 1-6 6 6 6 0 0 1 6 6 6 6 0 0 1 6-6 6 6 0 0 1-6-6M17.5 15a2.5 2.5 0 0 1-2.5 2.5 2.5 2.5 0 0 1 2.5 2.5 2.5 2.5 0 0 1 2.5-2.5 2.5 2.5 0 0 1-2.5-2.5',
  starCircle: 'M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10',
  star: 'M11 4.2a1 1 0 0 1 2 0l1.8 4.5 4.8.4a1 1 0 0 1 .6 1.7L16.5 14l1.1 4.7a1 1 0 0 1-1.5 1L12 17.3l-4.1 2.6a1 1 0 0 1-1.5-1.1L7.5 14l-3.7-3.2a1 1 0 0 1 .6-1.7l4.8-.4 1.9-4.5Z',
  store: 'M20 11.621V18a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-6.379M7.5 9.75a2.25 2.25 0 0 1-4.486.256c-.019-.17.008-.34.05-.504L4.11 5.495A2 2 0 0 1 6.045 4h11.91a2 2 0 0 1 1.935 1.495l1.045 4.007c.043.165.07.335.05.504A2.25 2.25 0 0 1 16.5 9.75m-9 0a2.25 2.25 0 0 0 4.5 0m-4.5 0L8 4m4 5.75a2.25 2.25 0 0 0 4.5 0m-4.5 0V4m4.5 5.75L16 4',
  thumbsDown: 'M8 4h9.192a2 2 0 0 1 1.832 1.198l2.808 6.419a2 2 0 0 1 .168.801V13a2 2 0 0 1-2 2h-6.5l1.207 4.424A1.36 1.36 0 0 1 14.003 21v0a1.36 1.36 0 0 1-1.687-.388L8.415 15.54A2 2 0 0 1 8 14.32V14M8 4H2v10h6M8 4v10',
  thumbsUp: 'M8 20h9.192a2 2 0 0 0 1.832-1.198l2.808-6.419a2 2 0 0 0 .168-.801V11a2 2 0 0 0-2-2h-6.5l1.207-4.424A1.36 1.36 0 0 0 14.003 3v0a1.36 1.36 0 0 0-1.687.388L8.415 8.46A2 2 0 0 0 8 9.68zm0 0H2V10h6z',
  userCircle: 'M18 18.708C17.483 16.375 15.536 15 12 15s-5.483 1.375-6 3.708M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18m0-9c1.333 0 2-.714 2-2.5S13.333 7 12 7s-2 .714-2 2.5.667 2.5 2 2.5',
  userGroup: 'M18.505 19H20c1.105 0 2.067-.924 1.716-1.971-.535-1.597-1.85-2.562-4.163-2.896M14.5 10.865a3.3 3.3 0 0 0 1 .135c1.667 0 2.5-.857 2.5-3s-.833-3-2.5-3a3.3 3.3 0 0 0-1 .136M9.5 14c3.614 0 5.54 1.01 6.216 3.029C16.066 18.076 15.105 19 14 19H5c-1.105 0-2.067-.924-1.716-1.971C3.96 15.009 5.887 14 9.5 14m0-3c1.667 0 2.5-.857 2.5-3s-.833-3-2.5-3S7 5.857 7 8s.833 3 2.5 3',
  user: 'M17.8 20c.7 0 1.2-.5 1.1-1.2-.3-3.2-2.4-4.8-6.9-4.8s-6.6 1.6-7 4.8c0 .7.5 1.2 1.2 1.2h11.6ZM12 11c2 0 3-1 3-3.5S14 4 12 4 9 5 9 7.5s1 3.5 3 3.5Z',
  verified: 'm15 10-4 4-2-2M4 5v7.056a8 8 0 0 0 4.422 7.155L12 21l3.578-1.789A8 8 0 0 0 20 12.056V5l-.697.077a10 10 0 0 1-6.917-1.801L12 3l-.386.276a10 10 0 0 1-6.917 1.801z',
};

const props = defineProps({
  size: {
    type: Number,
    default: 24,
  },
  icon: {
    type: String,
    default: 'star',
  },
});

const path = computed(() => icons[props.icon]);
</script>
