<template>
  <div class="employee-fragment">
    <button
      class="employee-fragment-main m-reset-button"
      type="button"
      @click="$emit('edit')"
    >
      <EmployeeFragment
        v-if="employeeGetter(employee)"
        inline
        :employee="employeeGetter(employee)"
      />
      <p v-else-if="answerCount">Počet odpovedí: {{ answerCount }}</p>
      <p v-else>Detail</p>
    </button>
    <button
      v-if="allowRemove"
      class="employee-fragment-remove m-reset-button"
      type="button"
      @click="$emit('remove')"
    >
      <BaseIcon icon="cross" />
    </button>
    <BaseIcon
      v-else
      icon="arrowRight"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';

import BaseIcon from '@/components/BaseIcon.vue';
import EmployeeFragment from '@/components/EmployeeFragment.vue';

const props = defineProps({
  activity: {
    type: Object,
    required: true,
  },
  employee: {
    type: Object,
    default: null,
  },
  employeeGetter: {
    type: Function,
    required: true,
  },
  allowRemove: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['edit', 'remove']);

const answerCount = computed(() => {
  if (props.activity.answers) {
    return Object.entries(props.activity.answers).length;
  }
  if (props.activity.screens) {
    return props.activity.screens.reduce((acc, cv) => acc + cv.questions.length, 0);
  }
  return null;
});

</script>

<style scoped>
.employee-fragment {
  display: flex;
  align-items: center;
  gap: 12px;
}

.employee-fragment-edit {
  background-color: var(--blue-5);
}

.employee-fragment-remove {
  background-color: var(--blue-5);
  border-radius: 50%;
}

.employee-fragment-main {
  flex: 1;
  text-align: left;
}
</style>
