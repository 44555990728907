<script setup>
import { watch } from 'vue';
import useListsStore from '@/stores/lists';
import useMeStore from '@/stores/me';
import {
  getBranches, getActivityTypes, getDashboardData, getEmployees, getUsers, getDocuments,
} from '@/api';

const lists = useListsStore();
const me = useMeStore();

const loadData = async () => {
  getDashboardData().then((data) => { me.dashboardData = data; });
  getBranches().then((data) => { lists.branches = data; });
  getActivityTypes().then((data) => { lists.activityTypes = data; });
  getEmployees().then((data) => { lists.employees = data; });
  getUsers().then((data) => { lists.users = data; });
  getDocuments().then((data) => { lists.documents.list = data; });
};

watch(
  () => me.token,
  (newValue) => {
    if (newValue) loadData();
  },
  { immediate: true },
);

</script>
