<template>
  <div>
    <ElevatedHeader>
      <div class="m-stack-horizontal">
        <AccountAvatar
          :size="56"
          :initials="me.formatted.initials"
        />
        <div>
          <h3>
            {{ me.formatted.name }}
          </h3>
          <p>
            {{ me.formatted.email }}
          </p>
        </div>
      </div>
    </ElevatedHeader>
    <div class="m-container">
      <ListItemIcon
        icon="password"
        :to="{ name: 'AuthChangePassword' }"
        label="Zmeniť heslo"
      />
      <ListItemIcon
        icon="questionCircle"
        href="https://www.posta.sk/"
        label="Podpora"
      />
      <BaseButton
        @click="logout()"
        variant="link-danger"
      >
        Odhlásiť sa
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import useMeStore from '@/stores/me';

import ElevatedHeader from '@/components/ElevatedHeader.vue';
import ListItemIcon from '@/components/ListItemIcon.vue';
import BaseButton from '@/components/BaseButton.vue';
import AccountAvatar from '@/components/AccountAvatar.vue';

const router = useRouter();
const me = useMeStore();

const logout = () => {
  me.logout();
  router.push({ name: 'Login' });
};

</script>

<style scoped>
.account-header-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--blue);
  color: var(--white);
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
