const normalizeString = (str) => {
  if (typeof str !== 'string') {
    return '';
  }
  return str
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export default normalizeString;
