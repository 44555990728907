<template>
  <BranchesList
    :branches="lists.branches"
    allowSelectAll
    @select="selectBranch"
  />
</template>

<script setup>
import { useRouter } from 'vue-router';
import useListsStore from '@/stores/lists';
import useStatsStore from '@/stores/stats';

import BranchesList from '@/components/BranchesList.vue';

const router = useRouter();
const lists = useListsStore();
const stats = useStatsStore();

const selectBranch = (branch) => {
  if (branch.id === 'ALL') {
    stats.filter.branch = 'ALL';
    stats.filter.branchesSpecific = [];
  } else {
    stats.filter.branch = 'SPECIFIC';
    stats.filter.branchesSpecific = [branch.id];
  }
  router.push({ name: 'Stats/List/Branches' });
};

</script>
