<template>
  <div class="m-container">
    <div class="intro-hero">
      <img
        class="intro-hero-icon"
        src="@/assets/slp-logo.svg"
        alt=""
      >
      <h1 class="intro-hero-title">
        eManažer
      </h1>
    </div>
    <PageHeader
      title="Prihláste sa"
      lead="Vitajte späť! Prihláste sa do svojho manažérskeho konta."
      center
    />
    <FormSheet
      @submit="submit()"
    >
      <FormGroup label="Emailová adresa">
        <InputText
          type="email"
          required
          v-model="email"
        />
      </FormGroup>
      <FormGroup label="Heslo">
        <InputText
          type="password"
          required
          v-model="password"
        />
      </FormGroup>
      <FormError v-if="isError">
        Nesprávne prihlasovacie údaje
      </FormError>
      <BaseButton type="submit">
        Prihlásiť sa
      </BaseButton>
      <p class="m-text-center">
        Nepamätáte si svoje heslo?
        <RouterLink
          :to="{ name: 'AuthRequestReset' }"
          class="m-font-bold m-color-blue"
        >
          Ťuknite sem
        </RouterLink>
      </p>
    </FormSheet>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useMetaStore from '@/stores/meta';
import { login } from '@/api';

import PageHeader from '@/components/PageHeader.vue';
import FormSheet from '@/components/FormSheet.vue';
import FormGroup from '@/components/FormGroup.vue';
import FormError from '@/components/FormError.vue';
import InputText from '@/components/InputText.vue';
import BaseButton from '@/components/BaseButton.vue';

const router = useRouter();
const meta = useMetaStore();

const isError = ref(false);
const email = ref('');
const password = ref('');

const submit = async () => {
  isError.value = false;
  meta.loading = true;
  try {
    const loginResponse = await login({
      email: email.value,
      password: password.value,
    });
    if (loginResponse) {
      router.push({ name: 'Dashboard' });
    }
  } catch (e) {
    isError.value = true;
  } finally {
    meta.loading = false;
  }
};

</script>

<style scoped>
.intro-hero {
  background-color: var(--yellow);
  color: var(--blue);
  border-radius: var(--border-radius-lg);
  height: 174px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 32px;
}

</style>
