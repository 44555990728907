<template>
  <div>
    <ElevatedHeader>
      <BranchFragment
        :branch="lists.getBranch(id)"
      />
      <p class="m-font-bold">
        Počet návštev: {{ visits.length }}
      </p>
    </ElevatedHeader>
    <div class="m-container">
      <RouterLink
        v-for="visit in visits"
        :key="visit.id"
        :to="{
          name: 'Stats/Detail',
          params: { id: visit.id },
        }"
        class="m-reset-link"
      >
        <ListItemVisit
          :branchGetter="lists.getBranch"
          :visit="visit"
        />
      </RouterLink>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import useStatsStore from '@/stores/stats';
import useListsStore from '@/stores/lists';

import ElevatedHeader from '@/components/ElevatedHeader.vue';
import BranchFragment from '@/components/BranchFragment.vue';
import ListItemVisit from '@/components/ListItemVisit.vue';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const stats = useStatsStore();
const lists = useListsStore();

const visits = computed(() => stats.visits.filter((visit) => visit.branch === props.id));

</script>
