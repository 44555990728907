const permissions = {
  DOCUMENTS_VIEW: 'DOCUMENTS_VIEW',
  DOCUMENTS_MANAGE: 'DOCUMENTS_MANAGE',
  VISIT_CREATE: 'VISIT_CREATE',
  VISIT_STATS_SELF: 'VISIT_STATS_SELF',
  VISIT_STATS_SUBORDINATES: 'VISIT_STATS_SUBORDINATES',
  VISIT_STATS_ALL: 'VISIT_STATS_ALL',
};

export default permissions;
