<template>
  <div class="elevated-header">
    <div class="m-container">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.elevated-header {
  background-color: var(--blue-5);
  color: var(--blue);
  border-bottom: var(--simple-border);
  padding-top: 24px;
  padding-bottom: 24px;
}
</style>
