import { parseISO, lightFormat } from 'date-fns';

const DEFAULT_DATE_FORMAT = 'd. M. yyyy';

const formatDate = (date, format = DEFAULT_DATE_FORMAT) => {
  if (!date) {
    return '';
  }
  const parsed = typeof date === 'object'
    ? date
    : parseISO(date);
  const formatted = lightFormat(parsed, format);
  return formatted;
};

export default formatDate;
