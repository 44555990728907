<template>
  <BaseSpinner
    v-if="isUploading"
  />
  <label
    v-else
    class="uploader-button"
    for="fileInput"
  >
    <BaseIcon
      :size="24"
      :icon="icon"
    />
    <span class="uploader-button-label m-font-size-200 m-font-bold">
      {{ label }}
    </span>
    <input
      type="file"
      ref="fileInputElement"
      id="fileInput"
      accept=".pdf"

      @change="handleNewFile"
    >
  </label>
</template>

<script setup>
import { ref } from 'vue';
import { uploadDocument } from '@/api';

import BaseIcon from '@/components/BaseIcon.vue';
import BaseSpinner from '@/components/BaseSpinner.vue';

const isUploading = ref(false);
const fileInputElement = ref(false);

defineProps({
  label: {
    type: String,
    default: 'Nahrať',
  },
  icon: {
    type: String,
    default: null,
  },
});

const emit = defineEmits('upload');

const handleNewFile = async () => {
  isUploading.value = true;
  const file = fileInputElement.value.files[0];
  const fileUpload = await uploadDocument(file);
  isUploading.value = false;
  emit('upload', fileUpload);
};

</script>

<style scoped>
.uploader-button {
  border: var(--simple-border);
  border-radius: var(--border-radius);
  padding: 16px;

  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    background-color: var(--gray-100);
  }
}
</style>
