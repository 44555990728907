<template>
  <div class="activity-cards">
    <div
      v-for="activityType in activityTypes"
      :key="activityType.id"
      role="button"
      class="activity-card"
      :class="{
        '-has-items': filterActivities(activityType.id).length,
      }"
    >
      <button
        class="activity-card-header m-reset-button"
        :class="{
          '-working': editorMode,
        }"
        type="button"
        @click="emit('addActivity', activityType.id)"
      >
        <BaseIcon
          :icon="getActivityIcon(activityType.id)"
          :size="32"
        />
        <div class="activity-card-name">
          {{ activityType.name }}
        </div>
      </button>

      <div
        v-if="filterActivities(activityType.id).length"
        class="activity-card-body"
      >
        <ActivityFragment
          v-for="(activity, i) in filterActivities(activityType.id)"
          :key="i"
          :activity="activity"
          :employee="activity.employee"
          :employeeGetter="employeeGetter"
          :allowRemove="editorMode"
          @remove="emit('removeActivity', i)"
          @edit="emit('openActivity', i)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import ActivityFragment from '@/components/ActivityFragment.vue';
import BaseIcon from '@/components/BaseIcon.vue';

const props = defineProps({
  activityTypes: {
    type: Array,
    default: () => [],
  },
  activities: {
    type: Array,
    default: () => [],
  },
  employeeGetter: {
    type: Function,
    default: () => ({}),
  },
  editorMode: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['openActivity', 'removeActivity', 'addActivity']);

const getActivityIcon = (id) => {
  const ICON_MAP = {
    'support-conversation': 'user',
    'manager-conversation': 'star',
    'branch-inspection': 'store',
  };
  return ICON_MAP[id] || 'chat';
};

const filterActivities = (type) => {
  const activitiesWithIndex = props.activities.map((activity, index) => ({
    ...activity,
    index,
  }));
  return activitiesWithIndex.filter((activity) => activity.type === type);
};

</script>

<style scoped>
.activity-cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.activity-card {
  background-color: var(--blue-5);
  border: var(--simple-border);
  border-radius: var(--border-radius);
  overflow: hidden;
  &.-has-items {
    order: 10; /* Push down cards with items */
    background-color: var(--white);
  }
}

.activity-card-header {
  color: var(--blue);
  cursor: pointer;
  padding: 16px;
  width: 100%;

  display: flex;
  gap: 16px;
  align-items: center;
  font-weight: 700;
  font-size: var(--font-size-300);
  &.-working:hover {
    background-color: var(--elevated);
  }
}

.activity-card-name {
  color: var(--gray-900);
}

.activity-card-body {
  border-top: 1px solid var(--gray-300);
  margin: 0 16px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

</style>
