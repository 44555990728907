<template>
  <div>
    <div class="new-visit-main">
      <RouterView
        @proceed="proceed()"
      />
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.new-visit-main {
  padding-bottom: calc(var(--app-bottombar-height) + 16px);
}

</style>
